<template>
    <div class="awards-detail-wrapper">
        <ViewTitle title="群英會獎項分數" :data-d="dropdownOptions" />
        <Dropdown
            class="col-dropdown"
            name="contestCol"
            :options="dropdownOptions"
            v-model="filter"
        />
        <SearchCondition
            :dataList="searchConditionList"
            :isShowTable="true"
            :isLoading="false"
            :isHasListData="true"
        />
        <AwardsDetailTable
            v-if="info.self"
            class="personal-detail"
            title="個人分數明細"
            :info="[{ title: info.self, value: '300分' }]"
            :isLoading="isLoading"
            :dataList="currentData.self"
            :config="config.self"
            :filterValue="filter"
        />
        <AwardsDetailTable
            v-if="info.underline"
            title="轄下分數明細"
            :info="[{ title: info.underline, value: '300分' }]"
            :isLoading="isLoading"
            :dataList="currentData.underline"
            :config="config.underline"
            :filterValue="filter"
        />
    </div>
</template>
<script>
import ViewTitle from '@/components/ViewTitle.vue'
import {
    awardsColumn,
    awardsDetailInfo,
    awardsColumnConfig,
    awardsDetailColumnConfig
} from '@/assets/javascripts/award/setting'
import _ from 'lodash'
import Dropdown from '@/components/Dropdown.vue'
import SearchCondition from '@/components/SearchCondition.vue'
import AwardsDetailTable from '@/containers/award/AwardsDetailTable.vue'
import { getAwardsDetailAPI } from '@/assets/javascripts/api'

export default {
    name: 'AwardsDetail',
    components: { ViewTitle, Dropdown, SearchCondition, AwardsDetailTable },
    computed: {
        dropdownOptions() {
            const config = awardsColumnConfig.group[this.rule]?.self
            const removeItem = [
                'serialNumber',
                'bgName',
                'orgBmName',
                'title',
                'total'
            ]
            const options = _.omit(_.pick(awardsColumn, config), removeItem)
            // TODO: value 待 code 確認後更新
            return Object.entries(options).map((opt) => {
                return { label: opt[1].label.replace(/\n/, ''), value: opt[0] }
            })
        },
        searchConditionList() {
            const contestRule =
                this.rule === 'sell'
                    ? '行銷組'
                    : this.rule === 'manager'
                    ? '經理組'
                    : '經營管理組'

            return [
                { title: '競賽名稱', value: '群英會' },
                { title: '競賽規則', value: contestRule },
                {
                    title: this.info.condition.title,
                    value: '1000 分',
                    tooltip: this.info.condition.tooltip
                }
            ]
        },
        currentData() {
            return {
                self: this.dataList[this.filter]?.self,
                underline: this.dataList[this.filter]?.underline
            }
        }
    },
    watch: {
        dropdownOptions() {
            this.filter = this.dropdownOptions[0].value
        },
        filter() {
            this.$router.replace({
                path: this.$route.path,
                query: {
                    filter: this.filter
                }
            })
            // table config
            const config = awardsDetailColumnConfig[this.rule]
            this.config = {
                self: (config.self && config.self[this.filter]) || [],
                underline:
                    (config.underline && config.underline[this.filter]) || []
            }
            // information
            this.info = awardsDetailInfo[this.rule][this.filter]
        }
    },
    data() {
        return {
            dataList: {},
            rule: '',
            filter: '',
            isLoading: false,
            config: { self: {}, underline: {} },
            info: { condition: {}, self: {}, underline: {} }
        }
    },
    async created() {
        window.scrollTo({ top: 0 })
        this.$showLoading()
        this.isLoading = true
        this.rule = this.$route.params.rule
        this.filter = this.$route.query.filter
        try {
            await new Promise((respond) => {
                setTimeout(async () => {
                    const res = getAwardsDetailAPI(this.payload)
                    this.dataList = res.data
                    respond()
                }, 1000)
            })
        } catch (error) {
            this.$showErrorMessage(
                '查無獎項明細，將返回獎項查詢',
                '查無獎項資料，請嘗試重新從獎項查詢列表開啟此頁面，若有疑問請洽飛鴿',
                () => {
                    this.$router.push({ name: 'Award' })
                }
            )
            this.dataList = {}
        } finally {
            this.isLoading = false
            this.$hideLoading()
        }
    }
}
</script>
<style lang="scss" scoped>
.awards-detail-wrapper {
    position: relative;
    :deep(.col-dropdown) {
        position: absolute;
        top: 0;
        right: 0;
        margin-bottom: 15px;
        .textbox-container > input {
            background-color: $primary-white;
        }
        .option-container {
            max-height: calc(50vh - 20px);
        }
        @media (max-width: 576px) {
            position: static;
            width: 100%;
        }
    }

    :deep(.condition-wrapper) {
        .mobile {
            .search-remark {
                justify-content: flex-start;
                .value:before {
                    content: '：';
                }
            }
        }
    }

    .personal-detail {
        margin-bottom: 50px;
    }
}
</style>
